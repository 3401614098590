@import url(~slick-carousel/slick/slick.css);
@import url(~slick-carousel/slick/slick-theme.css);
/**
Returns map with modifiers with properties based on passed component`s map
 */
/**
Returns list of class names (strings) based on passed component`s map
 */
/**
Returns generated placeholder name based on passed component`s map and type (modifier)
 */
/**
Generates CSS properties based on passed map, additionally you can pass map with nested selectors, you can also define inside of it custom properties as 'padding-vertical'
 */
/**
Groups modifier map by it's elements (including base styles)
*/
/**
Generates placeholders with properties of all modifiers of passed component`s map
 */
/**
Extends component in which was applied by passed modifiers of passed component`s map
 */
/* private */
/**
Generates modifier classes of component (i.e. [cmp_name]--[modifier-name] { ... }) based of passed component`s map
 */
/**
Returns property's value from passed map, $prop can handle nested values i.e. ('hover', 'color')
*/
/* Zwraca sciezke do pliku z obrazkiem (nadpisywana w Styleguide) */
/* Zwraca mape zawierajaca klucze podane w `$keys` */
/* example: map-deep-get($o-section, 'sizes', 'default', 'padding-vertical') */
/* z bliblioteki wykorzystywana jest funkcja cos() */
/* strip-unit jest zaciagany z _math */
/* Quarks settings */
/* Settings, Colors, Fonts */
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
@import url("//static.home.pl/static/assets/azo-sans.css");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/**
 1. Fix for Safari (adds 2px margin around <button>)
*/
button {
  margin: 0;
  /* 1 */ }

/* AZ colors */
/* HOME colors */
/*
1. Needed for correct display of slick-slider (https://github.com/kenwheeler/slick/issues/982#issuecomment-73517458)
*/
* {
  min-width: 0;
  /* 1 */
  min-height: 0;
  /* 1 */
  font-family: inherit; }
  *, *::before, *::after {
    box-sizing: border-box; }

/*
2. Prevents extend of page width
*/
html {
  height: 100%;
  overflow-x: hidden;
  /* 2 */
  font-size: 16px;
  /* stylelint-disable scale-unlimited/declaration-strict-value */ }

/*
3. Removes horizontal scrollbar on pages which contain organisms with negative margin
4. Hides <svg> which contains symbols with icons
*/
body {
  overflow-x: hidden;
  /* 3 */
  background: #e1e5e8;
  color: #1b1b1b;
  font-family: Azo Sans, sans-serif;
  line-height: 1.3; }
  body > svg {
    display: none;
    /* 4 */ }

h1, h2, h3, h4, h5, h6 {
  line-height: 1.4; }
  @media (min-width: 0) and (max-width: 39.9375em) {
    h1, h2, h3, h4, h5, h6 {
      line-height: 1.1; } }

h1 {
  margin-bottom: 1.25rem;
  font-size: 2.25rem;
  font-weight: 700; }

h2 {
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 700; }

h3 {
  margin-bottom: 1rem;
  font-size: 1.625rem;
  font-weight: 500; }

h4 {
  margin-bottom: 0.875rem;
  font-size: 1.5rem;
  font-weight: 700; }

h5 {
  margin-bottom: 0.75rem;
  font-size: 1.25rem;
  font-weight: 700; }

h6 {
  margin-bottom: 0.6875rem;
  font-size: 1.125rem;
  font-weight: 500; }

/* TODO: moze <p> powinno przyjmowac 18px jako default size? -> taki default jest ustawiony w .a-text */
p {
  margin-bottom: 0.625rem; }
  @media (min-width: 40em) {
    p {
      margin-bottom: 0.75rem; } }
  p:last-child {
    margin-bottom: 0; }

strong {
  font-weight: 700; }

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  /* Styles links without classes which occur inside <p> element */ }
  p a:not([class]) {
    text-decoration: underline; }
    p a:not([class]):hover {
      text-decoration: none; }

img {
  display: block;
  max-width: 100%; }

/* Used in other files */
input, select {
  width: 100%;
  height: 3.75rem;
  border: 0.125rem solid #adbac5;
  border-radius: 0.125rem;
  background-color: #fff;
  color: #1b1b1b;
  font-size: 1.125rem;
  font-weight: 500;
  appearance: none; }
  input:focus, select:focus {
    outline: none; }

::placeholder {
  color: #adbac5; }
  :hover:not(:focus)::placeholder {
    color: #697b8c; }

input {
  padding: 0rem 0.5625rem; }
  input:focus {
    border-color: #697b8c; }

select {
  padding: 0rem 1.375rem 0rem 0.5625rem;
  background-image: url("img/common/base/components/forms/arrow-down.svg");
  background-repeat: no-repeat;
  background-position: right 0.4375rem center;
  background-size: 0.5rem; }

textarea {
  width: 100%;
  padding: 0.5625rem;
  border: 0.125rem solid #adbac5;
  border-radius: 0.125rem;
  color: #1b1b1b;
  font-size: 1.125rem;
  resize: vertical; }
  textarea:focus {
    outline: none; }

[type=checkbox] {
  position: relative;
  flex-shrink: 0;
  width: 1.375rem;
  height: 1.375rem;
  margin: 0;
  padding: 0;
  border-radius: 0.25rem;
  cursor: pointer; }
  [type=checkbox]:focus {
    border-color: #adbac5; }
  [type=checkbox]:hover::after {
    background-image: url("img/common/base/components/forms/tick-gray.svg"); }
  [type=checkbox]:active {
    border-color: #1db51d; }
    [type=checkbox]:active::after {
      background-image: url("img/common/base/components/forms/tick-green.svg"); }
    [type=checkbox]:active::after {
      opacity: 0.4; }
  [type=checkbox]:checked {
    border-color: #1db51d; }
    [type=checkbox]:checked::after {
      background-image: url("img/common/base/components/forms/tick-green.svg"); }
    [type=checkbox]:checked:hover {
      border-color: #adbac5; }
  [type=checkbox]::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: no-repeat center; }

[type=radio] {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
  margin: 0;
  padding: 0;
  border-width: 0.0625rem;
  border-radius: 50%;
  cursor: pointer; }
  [type=radio]:hover::after {
    background-color: #adbac5; }
  [type=radio]:active {
    border-color: #1db51d; }
    [type=radio]:active::after {
      background-color: #1db51d; }
    [type=radio]:active:not(:checked)::after {
      opacity: 0.4; }
  [type=radio]:checked {
    border-color: #1db51d; }
    [type=radio]:checked::after {
      background-color: #1db51d; }
  [type=radio]::after {
    content: '';
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%; }

/* TODO: z-index - wyniesc do osobnego pliku */
body {
  position: relative;
  /* stylelint-disable-next-line selector-no-qualifying-type */ }
  body::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
    z-index: -1; }
  body.has-overlay {
    overflow: hidden; }
    body.has-overlay::after {
      transition: background-color 0.4s;
      z-index: 999999999;
      background-color: rgba(27, 27, 27, 0.95); }

/* TODO: Stworzyc z tego @function? -> Merge "default" z "primary" */
/* stylelint-disable declaration-no-important */
/**
1. Forces :hover to work when size of button is overwritten by a-btn-size() mixin
2. Forces gradient to fade when background-image is assigned to selector with higher specificity i.e. .a-btn.has-success
*/
.a-btn {
  min-height: 3.75rem;
  padding: 0.9375rem 1.875rem;
  border-radius: 0.375rem;
  font-size: 1.125rem;
  font-weight: 500;
  background-position: 0 -3.875rem;
  background-color: #e20000;
  background-image: linear-gradient(to bottom, #e51a1a, #cb0000);
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0.125rem solid transparent;
  text-align: center;
  cursor: pointer;
  user-select: none;
  transition: background-position 0.4s, box-shadow 0.4s;
  background-size: 0.0625rem calc(200% + 0.5rem);
  box-shadow: 0rem 0.125rem 0.3125rem rgba(105, 123, 140, 0.2);
  text-shadow: 0rem 0.0625rem 0.375rem rgba(27, 27, 27, 0.24); }
  .a-btn:active {
    transition: none; }
  .a-btn:hover {
    background-position: 0 -0.125rem !important;
    /* 1 */
    box-shadow: 0rem 0.125rem 0.625rem rgba(105, 123, 140, 0.25); }
  .a-btn:active {
    background-image: none !important;
    /* 2 */
    box-shadow: none; }

.a-btn--primary {
  background-color: #e20000;
  background-image: linear-gradient(to bottom, #e51a1a, #cb0000); }

.a-btn--secondary {
  background-color: #0ca6ff;
  background-image: linear-gradient(to bottom, #24afff, #0b95e6); }

.a-btn--success {
  background-color: #1db51d;
  background-image: linear-gradient(to bottom, #34bc34, #1aa31a); }

.a-btn--frame-white {
  border-color: #c9d3db;
  background-color: #fff;
  background-image: linear-gradient(to bottom, white, #f7f7f7);
  color: #193052;
  text-shadow: none; }

/* TODO: rozkminic jak zaciagac umiejscowienie ikony (obecnie trzeba recznie ustawiac ikone w komponencie do ktorego sa wstrzykiwane stany buttonow) */
.a-btn[class*=has-]::before, .a-btn[class*=is-]::before {
  width: 1.3125rem;
  height: 1.3125rem;
  background-size: 1.3125rem;
  content: ''; }

.has-success.a-btn {
  border-color: transparent;
  background-color: #1db51d;
  background-image: linear-gradient(to bottom, #34bc34, #1aa31a);
  color: #fff; }
  .has-success.a-btn::before {
    background-image: url("img/common/base/components/btn/tick.svg"); }

.has-error.a-btn {
  border-color: transparent;
  background-color: #e20000;
  background-image: linear-gradient(to bottom, #e51a1a, #cb0000);
  color: #fff; }
  .has-error.a-btn::before {
    background-image: url("img/common/base/components/btn/sad.svg"); }

.is-loading.a-btn {
  border-color: transparent;
  background-color: #0ca6ff;
  background-image: linear-gradient(to bottom, #24afff, #0b95e6);
  color: #fff;
  background-image: none;
  box-shadow: none;
  cursor: progress; }
  .is-loading.a-btn::before {
    background-image: url("img/common/base/components/btn/wait.svg"); }

.is-disabled.a-btn {
  border-color: #c9d3db;
  background-color: #fff;
  background-image: linear-gradient(to bottom, white, #f7f7f7);
  color: #193052;
  text-shadow: none;
  background-image: none;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 0.7; }
  .is-disabled.a-btn::before {
    background-image: url("img/common/base/components/btn/nope.svg"); }

.a-btn[class*=has-]::before, .a-btn[class*=is-]::before {
  margin-right: 0.9375rem;
  margin-left: -0.9375rem; }

/* TODO: tymczasowy mixin stowrzony na potrzebe podmiany mechanizmow starych buttonow - DO ANALIZY I POPRAWY */
/* TODO: Wyniesc do whate-label'a? Uspojnic i zebrac do kupy wszystkie errory zwiazane z formularzami */
.a-error-text {
  font-size: 0.875rem;
  color: #ff662a; }

/* TODO: size inherit? -> ustawiac size jezeli <a> nie znajduje sie wewnatrz <p>? */
.a-link {
  color: #1b1b1b;
  font-weight: 700;
  font-size: 1.125rem; }
  .a-link::after {
    background-color: #e20000; }

.a-link--black::after {
  background-color: #697b8c; }

.a-link--inverse {
  color: #fff; }

.a-link--medium {
  font-size: 1rem; }

.a-link--small {
  font-size: 0.875rem; }

/**
  1. Styles needed for <button> element styling
*/
.a-link {
  display: inline-flex;
  flex-direction: column;
  padding: 0;
  /* 1 */
  border: 0;
  /* 1 */
  background: none;
  /* 1 */
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  /* 1 */ }
  .a-link:hover::after {
    transform: scaleY(1.5) translateY(0.125rem); }
  .a-link:active::after {
    transform: scaleY(1.5);
    transition: none; }
  .a-link::after {
    transition: transform 0.4s;
    content: '';
    width: 100%;
    /* 1 */
    height: 0.125rem;
    margin-top: 0.125rem; }

.a-link--tiny {
  font-size: 0.75rem; }

.a-link--secondary::after {
  background-color: #697b8c; }

.a-link--underline {
  text-decoration: underline;
  font-weight: 700; }

.a-summary-box-title {
  color: #e20000;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-size: 0.75rem; }
  .a-summary-box-title--secondary {
    color: #1CAB1C; }

.a-text {
  line-height: 1.25rem;
  font-size: 0.875rem;
  margin-bottom: 0.875rem; }
  .a-text--success {
    color: #1db51d; }
  .a-text--secondary {
    color: #697b8c; }
  .a-text--secondary-tint {
    color: #1a4079; }
  .a-text--primary {
    color: #e20000; }
  .a-text--tiny {
    margin-bottom: 0.5rem;
    font-size: 0.75rem;
    font-weight: 500; }
  .a-text--small {
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem; }
  .a-text--big {
    line-height: 1.5rem;
    font-size: 1.25rem;
    margin-bottom: 1rem; }
  .a-text--center {
    text-align: center; }
  .a-text--medium {
    font-weight: 500; }

.m-checkout-text-with-sub__title {
  font-size: 1.125rem;
  color: #1b1b1b; }

.m-checkout-text-with-sub--secondary .m-checkout-text-with-sub__title {
  font-size: 0.875rem;
  font-weight: 500; }

.m-checkout-text-with-sub--secondary .m-checkout-text-with-sub__subtext {
  color: #697b8c;
  font-size: 0.75rem; }

.m-checkout-text-with-sub__title {
  margin-bottom: 0.1875rem;
  font-weight: 500; }

.m-checkout-text-with-sub__subtext {
  color: #697b8c;
  font-size: 0.75rem;
  font-weight: 500; }

.m-checkout-text-with-sub--secondary .m-checkout-text-with-sub__title {
  margin-bottom: 0.3125rem;
  color: #e20000; }

.m-checkout-text-with-sub__subtext {
  color: #1a4079;
  font-size: 0.875rem; }

.m-desc-item {
  position: relative; }
  .m-desc-item:not(:last-child) {
    margin-bottom: 1.125rem; }
  .m-desc-item__img {
    position: absolute;
    top: 0.25rem;
    width: 1rem;
    height: 1rem;
    transform: none;
    fill: #DADADA; }
  .m-desc-item__content {
    margin-left: 1.75rem; }
  .m-desc-item__title {
    margin-bottom: 0.1875rem;
    font-size: 1.125rem;
    font-weight: 500; }
  .m-desc-item__text {
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 140%; }
    .m-desc-item__text:not(:last-child) {
      margin-bottom: 0.375rem; }

/* TODO: do podmiany przy update'cie input- i submit-field'ow */
.m-input-field {
  position: relative;
  display: flex;
  align-items: center; }
  .m-input-field__info {
    transition: color 0.4s, top 0.4s, font-size 0.4s;
    display: flex;
    position: absolute;
    top: 50%;
    left: 0.6875rem;
    transform: translateY(-50%);
    color: #c9d3db;
    font-size: 1.125rem;
    font-weight: 700;
    text-transform: uppercase;
    z-index: -1;
    pointer-events: none; }
  .m-input-field__input {
    transition: color 0.4s, border-color 0.4s;
    flex: 1;
    cursor: inherit;
    overflow: hidden;
    letter-spacing: 0.09375rem; }
  .m-input-field__toggle {
    transition: opacity 0.4s;
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 2.125rem;
    cursor: pointer;
    opacity: 0;
    z-index: -1;
    top: 0;
    bottom: 0; }
    .m-input-field__toggle span {
      display: block;
      margin-bottom: 0.25rem;
      font-size: 0.5rem;
      font-weight: 700;
      text-transform: uppercase; }
    .m-input-field__toggle svg {
      width: 1rem;
      height: 1rem; }
  .m-input-field--password .m-input-field__toggle {
    right: 0.625rem;
    opacity: 1;
    z-index: 10; }
  .m-input-field.has-value .m-input-field__info {
    top: 1.125rem;
    color: #697b8c;
    font-size: 0.625rem;
    z-index: 1; }
  .m-input-field.has-value .m-input-field__input {
    transition: color 0.4s, border-color 0.4s, padding-top 0.4s;
    padding-top: 1rem; }
  .m-input-field.has-success .m-input-field__info {
    color: #1db51d; }
  .m-input-field.has-success .m-input-field__input {
    border-color: #1db51d; }
  .m-input-field.has-error .m-input-field__info {
    color: #ff662a; }
  .m-input-field.has-error .m-input-field__input {
    border-color: #ff9d65; }
    .m-input-field.has-error .m-input-field__input:focus {
      border-color: #ff662a; }

.m-login-list {
  font-size: 0.75rem; }
  .m-login-list::before {
    display: block;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 0.0625rem solid #c9d3db;
    color: #697b8c;
    font-weight: 700;
    content: attr(data-title); }
  .m-login-list li {
    display: flex;
    align-items: center;
    padding-left: 0.3125rem; }
    .m-login-list li:not(:last-child) {
      margin-bottom: 0.75rem; }
    .m-login-list li::before {
      display: block;
      width: 0.375rem;
      height: 0.375rem;
      margin-top: -0.125rem;
      margin-right: 0.625rem;
      border-radius: 50%;
      background-color: #adbac5;
      content: ''; }
  .m-login-list p {
    color: #193052; }
  .m-login-list a {
    font-weight: 500; }

.m-summary-box-text {
  color: #312f2f;
  font-size: 0.8125rem;
  line-height: 1.5; }
  .m-summary-box-text:last-child {
    margin-bottom: 0; }
  .m-summary-box-text strong {
    display: block;
    margin-bottom: 0.3125rem;
    font-size: 0.875rem; }

.m-tick-list {
  color: #1b1b1b;
  font-size: 0.75rem; }
  .m-tick-list:not(:last-child) {
    margin-bottom: 0.75rem; }
  .m-tick-list li {
    position: relative;
    margin-bottom: 0.625rem;
    padding-left: 1.875rem;
    line-height: 1.5; }
    .m-tick-list li:last-child {
      margin-bottom: 0; }
    .m-tick-list li::before {
      content: '';
      position: absolute;
      top: 0.1875rem;
      left: 0;
      width: 0.9375rem;
      height: 0.75rem;
      background: url("img/common/base/components/forms/tick-green.svg") no-repeat center; }

.m-validator {
  display: flex;
  margin-top: 0.375rem;
  /* 1 */ }
  .m-validator__item {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0 0.125rem;
    color: #697b8c;
    font-size: 0.625rem;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase; }
    .m-validator__item::before {
      height: 0.25rem;
      margin-bottom: 0.3125rem;
      border-radius: 0.25rem;
      background-color: #adbac5;
      content: ''; }
    .m-validator__item.has-success {
      color: #1db51d; }
      .m-validator__item.has-success::before {
        background-color: #1db51d; }
    .m-validator__item.has-error {
      color: #ff662a; }
      .m-validator__item.has-error::before {
        background-color: #ff662a; }

.m-version-text {
  font-size: 0.75rem;
  color: #697b8c;
  text-align: center; }
  @media (min-width: 62.5em) {
    .m-version-text {
      position: fixed;
      left: 0.3125rem;
      bottom: 0.3125rem;
      padding: 0.5rem;
      font-size: 0.875rem;
      background-color: #e1e5e8; } }

@media (min-width: 40em) {
  .o-login-header, .o-summary-box {
    margin-right: -6.25rem;
    margin-left: -6.25rem; } }

@media (min-width: 40em) {
  .o-cta {
    display: flex;
    align-items: center; } }

@media (min-width: 40em) {
  .o-cta > * {
    margin-left: 1.875rem; } }

.o-cta > *:first-child {
  margin-left: 0; }
  @media (min-width: 0) and (max-width: 39.9375em) {
    .o-cta > *:first-child {
      margin-bottom: 1.875rem; } }

.o-cta__btn {
  flex: 1; }
  .o-cta__btn:nth-child(2) {
    flex: calc(2 / 3); }
  .o-cta__btn > * {
    width: 100%; }

.o-cta__link {
  flex: 1;
  text-align: center; }

.o-cta--vertical {
  flex-direction: column;
  align-items: stretch; }
  .o-cta--vertical > * {
    margin-left: 0; }
    .o-cta--vertical > *:first-child {
      margin-bottom: 1.875rem; }

.o-aside-slider {
  transition: right 0.4s;
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  width: 26.875rem;
  height: 100%;
  overflow: hidden;
  /* stylelint-disable selector-class-pattern */ }
  @media (min-width: 62.5em) {
    .o-aside-slider {
      display: block; } }
  .o-aside-slider__slide {
    position: relative;
    height: 100%;
    padding: 3.5625rem 2.5rem; }
    .o-aside-slider__slide--white {
      background-color: #fff; }
    .o-aside-slider__slide-wrapper {
      height: 100%;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center bottom; }
    .o-aside-slider__slide span {
      display: inline-block;
      margin-bottom: 0.875rem; }
    .o-aside-slider__slide img {
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      margin-bottom: 1.0625rem; }
    .o-aside-slider__slide p {
      margin-bottom: 1.6875rem; }
  .o-aside-slider .slick-list,
  .o-aside-slider .slick-track,
  .o-aside-slider .slick-slide {
    height: 100%; }
  .o-aside-slider .slick-slide > * {
    height: 100%; }
  .o-aside-slider .slick-dots {
    display: flex !important;
    position: absolute;
    bottom: 1.875rem;
    left: 2.5rem; }
    .o-aside-slider .slick-dots li {
      width: auto;
      height: auto;
      margin-left: 0;
      /* stylelint-disable selector-no-qualifying-type */ }
      .o-aside-slider .slick-dots li button {
        width: 0.9375rem;
        height: 0.9375rem;
        border-radius: 100%;
        background-color: #1b1b1b;
        cursor: pointer; }
        .o-aside-slider .slick-dots li button:before {
          display: none; }
      .o-aside-slider .slick-dots li:not(:last-child) {
        margin-right: 1.125rem; }
      .o-aside-slider .slick-dots li.slick-active button {
        background-color: #e20000; }

.o-form__field:nth-child(3), .o-form__aside {
  flex: 0 0 9.375rem; }

.o-form {
  /* stylelint-disable order/order */ }
  .o-form:not(:last-child) {
    margin-bottom: 3.75rem; }
  @media (min-width: 40em) {
    .o-form__row {
      display: flex;
      align-items: flex-start; } }
  .o-form__row:not(:last-child) {
    margin-bottom: 0.625rem; }
  @media (min-width: 0) and (max-width: 39.9375em) {
    .o-form__row > * {
      margin-bottom: 0.625rem; } }
  .o-form__row > *:only-child {
    flex: 1; }
  .o-form__row > *:last-child {
    margin-bottom: 0; }
  .o-form__errors {
    margin-top: 2.5rem; }
  .o-form__field {
    flex: 1;
    /* stylelint-disable-next-line order/order */ }
    @media (min-width: 40em) {
      .o-form__field:not(:first-child) {
        margin-left: 0.625rem; } }
    .o-form__field:nth-child(2) {
      flex: 3; }
    .has-equal-fields .o-form__field:nth-child(2) {
      flex: 1; }
  .o-form__aside {
    display: none; }
    @media (min-width: 40em) {
      .o-form__aside {
        display: flex;
        align-items: center;
        min-height: 3.75rem;
        margin-left: 0.625rem;
        padding-left: 0.625rem; } }
    @media (min-width: 0) and (max-width: 39.9375em) {
      .has-focus .o-form__aside {
        display: block;
        margin-bottom: 1.25rem; } }
  .o-form__submit {
    flex: 1;
    margin-top: 1.25rem;
    /* TODO: do poprawy? */
    /* stylelint-disable-next-line order/order */ }
    .o-form__submit > * {
      width: 100%; }
    .o-form__submit.has-full-width {
      margin-right: 0; }
    @media (min-width: 0) and (max-width: 39.9375em) {
      .o-form__submit + .o-form__aside {
        display: block;
        text-align: center; } }
    @media (min-width: 40em) {
      .o-form__submit + .o-form__aside {
        margin-top: 1.25rem; } }
  @media (min-width: 40em) {
    .o-form.has-aside .o-form__field:only-child {
      margin-right: 10rem; } }
  @media (min-width: 40em) {
    .o-form.has-aside .o-form__submit:only-child {
      margin-right: 10rem; } }
  .o-form--wider-aside .o-form__field:nth-child(3),
  .o-form--wider-aside .o-form__aside {
    flex-basis: 11.25rem; }
  @media (min-width: 40em) {
    .o-form--wider-aside .o-form__submit:only-child,
    .o-form--wider-aside .o-form__field:only-child {
      margin-right: 11.875rem !important; } }

@media (min-width: 40em) {
  .o-links-with-description {
    display: flex;
    align-items: baseline; } }

.o-links-with-description__desc {
  margin-bottom: 0.625rem; }
  @media (min-width: 40em) {
    .o-links-with-description__desc {
      flex: 1;
      margin-bottom: 0; } }
  .o-links-with-description__desc > * {
    margin-bottom: 0; }

.o-links-with-description__links {
  display: flex;
  justify-content: space-around; }
  .o-links-with-description__links > * {
    margin-right: 0.625rem; }
    .o-links-with-description__links > *:last-child {
      margin-right: 0; }

.o-links-with-description--flex {
  display: flex;
  align-items: center;
  justify-content: center; }
  .o-links-with-description--flex__label {
    display: flex;
    justify-content: center;
    align-items: centers;
    flex-shrink: 0;
    margin-right: 0.25rem;
    padding: 0.25rem 0.5rem;
    background: #0094ea;
    border-radius: 0.1875rem;
    font-size: 0.8125rem;
    line-height: 1rem;
    color: #ffffff;
    opacity: 0.8;
    cursor: default; }
  .o-links-with-description--flex__text {
    font-size: 0.8125rem; }

.label-yellow {
  background: #fff480;
  color: #000;
  font-weight: 500; }

.o-login-change {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .o-login-change p {
    margin: 0; }
  .o-login-change__old {
    position: relative; }
    .o-login-change__old::after {
      content: '';
      position: absolute;
      top: 50%;
      width: 100%;
      height: 0.125rem;
      background-color: rgba(255, 102, 42, 0.6); }
  .o-login-change__arrow {
    width: 1.5625rem;
    height: 1.625rem;
    margin: 0.375rem 0;
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjciIHZpZXdCb3g9IjAgMCAyMiAyNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1LjcgMS41VjExLjM5MDZDMTUuNyAxMS44MTMgMTYuMDY3MSAxMi4xNDE2IDE2LjQ4NjkgMTIuMDk0OUwyMC40MzEgMTEuNjU2N0MyMS4wMzg4IDExLjU4OTEgMjEuNDQxIDEyLjI3MTUgMjEuMDg3NSAxMi43NzA2TDExLjcyODIgMjUuOTgzN0MxMS40NDU4IDI2LjM4MjMgMTAuODU0MiAyNi4zODIzIDEwLjU3MTggMjUuOTgzN0wxLjIxMjQ4IDEyLjc3MDZDMC44NTkwMDMgMTIuMjcxNSAxLjI2MTE5IDExLjU4OTEgMS44Njg5OCAxMS42NTY3TDUuODEzMTMgMTIuMDk0OUM2LjIzMjg5IDEyLjE0MTYgNi42IDExLjgxMyA2LjYgMTEuMzkwNlYxLjUiIHN0cm9rZT0iI0FEQkFDNSIgc3Ryb2tlLXdpZHRoPSIxLjQxNzIzIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==") no-repeat center; }

.o-label-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.625rem; }
  .o-label-bar:last-child {
    margin-bottom: 0; }
  .o-label-bar__cta {
    flex-shrink: 0;
    padding-left: 0.625rem; }

.o-login-container {
  width: 100%;
  max-width: 28rem;
  margin: auto;
  padding: 3.125rem 0.625rem; }

body,
#root {
  height: 100%; }

.o-login-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3.125rem; }
  .o-login-header__logo {
    margin-bottom: 1.25rem; }
  .o-login-header__title {
    width: 100%;
    text-align: center; }
    .o-login-header__title > * {
      margin-bottom: 0; }

.o-login-section {
  display: flex;
  height: 100%; }
  @media (min-width: 62.5em) {
    .o-login-section {
      transition: padding-right 0.4s;
      padding-right: 26.875rem; } }
  .o-login-section.has-hidden-sidebar {
    padding-right: 0; }
    .o-login-section.has-hidden-sidebar .o-aside-slider {
      right: -26.875rem; }

.o-summary-box {
  margin-bottom: 3.625rem;
  padding: 1.25rem 1.875rem;
  border-radius: 0.625rem;
  background: #fff; }
  .o-summary-box__header {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 0.125rem solid #c9d3db;
    text-transform: uppercase; }
  .o-summary-box__section {
    margin-bottom: 1.875rem; }
    .o-summary-box__section:last-child {
      margin-bottom: 0; }

.u-mb45 {
  margin-bottom: 2.8125rem !important; }
